import React, { useState, useEffect } from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import styled from "styled-components";
import familyFullWide from "../../static/images/homepage-assets/family-wide.jpeg";
import familyFull from "../../static/images/homepage-assets/family.jpeg";
import familyMobile from "../../static/images/homepage-assets/family-mobile.png";
import wsj from "../../static/images/bankruptcy-assets/wsj.png";
import wpost from "../../static/images/bankruptcy-assets/wpost.png";
import nytimes from "../../static/images/bankruptcy-assets/nytimes.png";
import time from "../../static/images/bankruptcy-assets/time.png";
import fastcompany from "../../static/images/bankruptcy-assets/fastcompany.png";
import forbes from "../../static/images/bankruptcy-assets/forbes.png";
import IconCaret from "../../static/images/bankruptcy-assets/chevron-down.svg";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import upsolveJSONLD from "../components/SEO/upsolveJSONLD";
import webApplicationJSONLD from "../components/SEO/webApplicationJSONLD";
import SEO from "../components/SEO/SEO";
import richTextNodeToComponent from "../components/Contentful/richTextNodeToComponent";
import ImpactMap from "../components/homePage/ImpactMap";
import { track } from "../components/analytics/track";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import { EVENTS } from "@upsolve/shared";
import upsolveStats from "../../content/onBuild/upsolveStats.json";
import IconSignPost from "../../static/images/homepage-assets/signpost.svg";
import mountainPNG from "../../static/images/homepage-assets/mountain.png";
import { CTATarget } from "../components/CTA/getCTAData";
import { ReviewsWall } from "../components/reviews/ReviewsWall";
import { DebtAdvisorModal } from "../components/DebtAdvisor/DebtAdvisorModal";
import { createPortal } from "react-dom";

const contentGroup = CONTENT_GROUPS.HOME_PAGE;

const trackingPageContext = {
  pageComponentName: "FrontPage",
  pageComponentVersion: "5",
  contentGroup,
  searchTermTargets: ["upsolve"],
};

export default function FrontPage() {
  const [showTriage, setShowTriage] = useState(false);

  useEffect(() => {
    if (typeof window !== undefined) {
      const urlReferrer = new URLSearchParams(window.location.search).get("referrer");
      if (urlReferrer) {
        track(EVENTS.COMPONENT_ACTION, {
          componentName: `homePage.referrer`,
          componentVersion: "0",
          actionId: "homePage.triageOpened",
          actionMethod: "click",
          pageContext: trackingPageContext,
        });
        setShowTriage(true);
      }
    }
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }, []);
  const onClickGetDebtHelpButton = (specificComponentName?: string) => {
    track(EVENTS.COMPONENT_ACTION, {
      componentName: `homePage${specificComponentName ? `.${specificComponentName}` : ""}`,
      componentVersion: "0",
      actionId: "homePage.triageOpened",
      actionMethod: "click",
      pageContext: trackingPageContext,
    });
    setShowTriage(true);
  };
  const HelpCardInfo: THelpCardContent[] = [
    {
      icon: IconMountain,
      title: "Debt Relief Tools",
      body: "Use one of our free tools to dispute your debt, negotiate your debt, or file Chapter 7 bankruptcy. 13,000 members have used our tools to eliminate $600+ million in debt. Always 100% free.",
      onClick: () => {
        track(EVENTS.COMPONENT_ACTION, {
          componentName: "homePage.helpCard.DebtReliefTools",
          componentVersion: "0",
          actionId: `homePage.helpCard.DebtReliefTools`,
          actionMethod: "click",
          pageContext: trackingPageContext,
        });
        setShowTriage(true);
      },
    },
    {
      icon: IconSignPost,
      title: "Education",
      body: "Our writers and editors also have your back, with diligently researched and unbiased content that answers all your questions about getting out of debt and building credit.",
      onClick: () => {
        track(EVENTS.COMPONENT_ACTION, {
          componentName: "homePage.helpCard.Education",
          componentVersion: "0",
          actionId: `homePage.helpCard.Education`,
          actionMethod: "click",
          pageContext: trackingPageContext,
        });
        navigate("/learn");
      },
    },
  ];

  useEffect(() => {
    //prevent scrolling on background behind triage: add noscroll property to body when the overlay is up. there's probably a better solution out there. https://stackoverflow.com/questions/9280258/prevent-body-scrolling-but-allow-overlay-scrolling
    if (showTriage) document.body.classList.toggle("noscroll", true);
    else document.body.classList.toggle("noscroll", false);
  }, [showTriage]);

  return (
    <TopLevelLayout showPersistentCta={false}>
      <div className="index-container" style={{ pointerEvents: showTriage ? "none" : undefined }}>
        <SEO
          title="Take Control of Your Debt | Free Debt Relief Help | Nonprofit"
          description="Upsolve is America's #1 source for unbiased debt relief help and information. Donor-funded and featured 4x in Forbes."
          url="https://upsolve.org/"
          jsonLD={[upsolveJSONLD, webApplicationJSONLD]}
        />
        {/* TODO: new header */}
        <Header allowMobileFooter={false} ctaTarget={CTATarget.DEBT_TRIAGE} trackingPageContext={trackingPageContext} />
        <HomePage>
          <section className="hero">
            <div className="hero__content content">
              <div className="hero__card">
                <h1>Take control of your debt</h1>
                <p>Our nonprofit helps you get out of debt with free debt relief tools and education.</p>
                <GetDebtHelpButton onClickGetDebtHelpButton={onClickGetDebtHelpButton} specificComponentName="hero" />
              </div>
              <div className="hero__members-number">
                <h1>Join 350,000 members</h1>
                {/* TODO: Increase the accuracy of this number. It might be higher: https://www.notion.so/upsolve/Increase-Our-Discharge-Total-Accuracy-474c852e506940ea810416ca5639f6a7 */}
                <p>who have eliminated $600+ million in debt since 2017 with Upsolve's help.</p>
              </div>
              <div className="hero__badges-container">
                <div className="hero__badges">{badges.map((badge) => badge)}</div>
              </div>
            </div>
          </section>
          <section className="help">
            <div className="help__content content">
              {/* TODO: center on widescreen */}
              <h1>How can Upsolve help you?</h1>
              <p>We are a 501(c)3 nonprofit organization that helps Americans struggling with debt in two ways:</p>
              <div className="help__content__ways">
                {HelpCardInfo.map((helpCard) => (
                  <HelpCard {...helpCard} />
                ))}
              </div>
            </div>
          </section>
          <section className="impact-map" id="impact">
            <div className="impact-map__content content">
              <div className="impact-map__debt-relieved">
                <h2>Total debt relieved by Upsolve members since 2017:</h2>
                <h1 className="impact-map__debt-relieved__number">
                  $
                  {Number(upsolveStats.debtCleared.total).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </h1>
              </div>

              <ImpactMap />
            </div>
          </section>
          <section className="hear-from-members">
            <div className="hear-from-members__content content">
              <h1>Hear from our members</h1>
              <ReviewsWall />
            </div>
          </section>
          <section className="faq">
            <div className="faq__content content">
              <h1 className="faq__content__header">Is Upsolve Right for You?</h1>
              <FAQ />
            </div>
          </section>
          <section className="trust">
            <div className="trust__content content">
              <h1>A nonprofit you can trust</h1>
              <p>
                As a 501(c)(3) nonprofit dedicated to helping you overcome debt, we value your privacy and dignity. We
                will never sell your data and our tools will always be free.
              </p>
              <GetDebtHelpButton onClickGetDebtHelpButton={onClickGetDebtHelpButton} specificComponentName="trust" />
            </div>
          </section>
        </HomePage>
        {/* TODO: new footer */}
        <Footer bio={false} />
      </div>
      {showTriage &&
        createPortal(
          <DebtAdvisorModal onHide={() => setShowTriage(false)} trackingPageContext={trackingPageContext} />,
          document.body
        )}
    </TopLevelLayout>
  );
}

// used a png instead of svg because the image in the svg version had a thin artifact line in it. We could remove it using a vector graphics editor but this is fine for now.
const IconMountain = styled.div`
  background: url("${mountainPNG}");
  width: 100px;
  height: 101px;
`;
type THelpCardContent = {
  icon: React.Component;
  title: string;
  body: string;
  onClick: () => void;
};

const HelpCard = (helpCardContent: THelpCardContent) => {
  return (
    <div key={helpCardContent.title} className="help__content__ways__card" onClick={() => helpCardContent.onClick()}>
      <div className="help__content__ways__card__text">
        <helpCardContent.icon />
        <h2>{helpCardContent.title}</h2>
        <p>{helpCardContent.body}</p>
      </div>
      <p className="help__content__ways__card__link">Learn More {">"}</p>
    </div>
  );
};

const HomePageFAQItem = ({ question, answer }: { question: string; answer: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="faq__question" onClick={() => setIsOpen(!isOpen)}>
      <div className={`faq__question__header ${isOpen ? "opened" : "closed"}`}>
        <div>{question}</div> <IconCaret />
      </div>
      {isOpen && <div>{answer}</div>}
    </div>
  );
};

const FAQ = () => {
  const { faq } = useStaticQuery(graphql`
    query HomePageFAQQuery {
      faq: contentfulFaqHcj(title: { eq: "homepage-debtsolve-faq" }) {
        questions {
          id
          text {
            text
          }
          answers {
            id
            text {
              text
            }
          }
        }
      }
    }
  `);

  const questions: { question: string; answer: string }[] = faq?.questions?.map((q: any) => {
    return { question: q.text.text, answer: richTextNodeToComponent(JSON.parse(q.answers[0].text.text)) };
  });
  return (
    <StyledHomePageFAQ>
      {questions?.map((question, index) => (
        <HomePageFAQItem key={index} question={question.question} answer={question.answer} />
      ))}
    </StyledHomePageFAQ>
  );
};

const StyledHomePageFAQ = styled.div`
  width: 100%;
  max-width: 633px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13.333px;
  @media screen and (max-width: 940px) {
    max-width: 340px;
  }
  .faq__question {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 6.7px;
    border: 0.667px solid var(--Gray-3, #828282);
    width: 100%;
    padding: 15px 13.333px;
    gap: 15px;
    box-sizing: border-box;
    cursor: pointer;
    &__header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-size: 18.667px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      svg {
        transition: 250ms;
      }
      &.opened {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    &__text {
      ul {
        list-style-type: initial;
      }
    }
  }
`;

const ForbesLogo = styled.a`
  background: url("${forbes}");
  width: 68px;
  height: 28.667px;
  flex-shrink: 0;
`;
const WSJLogo = styled.a`
  background: url("${wsj}");
  width: 211.333px;
  height: 35.333px;
  flex-shrink: 0;
`;
const WPLogo = styled.a`
  background: url("${wpost}");
  width: 194px;
  height: 18.667px;
  flex-shrink: 0;
`;
const NYTLogo = styled.a`
  background: url("${nytimes}");
  width: 180.667px;
  height: 26px;
  flex-shrink: 0;
`;
const FCLogo = styled.a`
  background: url("${fastcompany}");
  width: 111.03px;
  height: 16px;
  flex-shrink: 0;
`;
const TimeLogo = styled.a`
  background: url("${time}");
  width: 56.133px;
  height: 70.167px;
  flex-shrink: 0;
`;

const badges = [
  <TimeLogo target="_blank" href="https://time.com/collection/best-inventions-2020/5911367/upsolve/" />,
  <WSJLogo
    target="_blank"
    href="https://www.wsj.com/articles/for-struggling-consumers-a-cheaper-way-to-file-for-bankruptcy-1505096071"
  />,
  <NYTLogo target="_blank" href="https://www.nytimes.com/2018/12/21/technology/year-in-technology-2018.html" />,
  <ForbesLogo
    target="_blank"
    href="https://www.forbes.com/sites/chrisdenhart/2018/09/19/upsolve-moves-upward-under-30-cofounders-bankruptcy-service-is-just-one-click-away/#2d4916916747"
  />,
  <FCLogo target="_blank" href="https://www.fastcompany.com/90457894/not-for-profit-most-innovative-companies-2020" />,
  <WPLogo
    target="_blank"
    href="https://www.washingtonpost.com/outlook/what-happens-when-you-cant-afford-to-go-bankrupt/2018/03/02/343fd882-1d8e-11e8-9de1-147dd2df3829_story.html"
  />,
];

const GetDebtHelpButton = ({
  onClickGetDebtHelpButton,
  specificComponentName,
}: {
  onClickGetDebtHelpButton: (specificComponentName: string | undefined) => void;
  specificComponentName?: string;
}) => {
  return <StyledButton onClick={() => onClickGetDebtHelpButton(specificComponentName)}>Get Debt Help</StyledButton>;
};
const StyledButton = styled.button`
  display: flex;
  width: 166.667px;
  height: 40px;
  padding: 6.667px 10.667px;
  justify-content: center;
  align-items: center;
  gap: 5.333px;
  border-radius: 34.667px;
  border: 0.667px solid #d0d5dd;
  background: #3c5dff;

  color: var(--White, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
  cursor: pointer;
  &:hover {
    background: #324edb;
  }

  @media screen and (max-width: 940px) {
    width: 240px;
    height: 44px;
    padding: 10px 16px;
    gap: 8px;
    border-radius: 31px;
    font-size: 16px;
    line-height: 20px; /* 125% */
  }
`;
const HomePage = styled.main`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  h1 {
    font-family: "Castoro";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    @media screen and (max-width: 940px) {
      font-size: 30px;
    }
  }
  h2 {
    font-family: "Castoro";
    font-size: 26px;
    font-weight: 400;
    line-height: normal;
    font-style: normal;
    @media screen and (max-width: 940px) {
      font-size: 20px;
    }
  }
  h3 {
    font-size: 24px;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media screen and (max-width: 940px) {
      font-size: 18px;
    }
  }
  p {
    /* Desktop/Body */
    font-size: 20px;
    font-style: normal;
    /* font-weight: 400; */
    line-height: normal;
    margin: 0;
    @media screen and (max-width: 940px) {
      font-size: 16px;
    }
  }

  section {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .hide-mobile {
    display: block;
  }
  .hide-widescreen {
    display: none;
  }
  @media screen and (max-width: 940px) {
    .hide-mobile {
      display: none;
    }
    .hide-widescreen {
      display: block;
    }
  }

  .content {
    max-width: 1280px;
  }
  .hero {
    display: flex;
    height: 666px;
    flex-shrink: 0;
    /* TODO: find a different photo or make a montage */
    background: linear-gradient(12deg, rgba(0, 0, 0, 0.5) 39.21%, rgba(0, 0, 0, 0) 56.46%), url(${familyFull}) no-repeat,
      lightgray -40.771px 10.159px / 110.684% 142.265%;

    background-size: cover;
    /* FYI at screen sizes between phone and desktop, the current photo and css doesn't look good. this is a do later because there are few tablet users. */
    @media screen and (max-width: 440px) {
      display: inline-flex;
      height: 903px;
      align-items: flex-end;
      gap: 34px;
      padding: 42px 0;
      background: url(${familyMobile}) no-repeat, lightgray;
    }

    @media screen and (min-width: 2560px) {
      background: linear-gradient(12deg, rgba(0, 0, 0, 0.5) 39.21%, rgba(0, 0, 0, 0) 56.46%),
        url(${familyFullWide}) no-repeat, lightgray -40.771px 10.159px / 110.684% 142.265%;
      background-size: cover;
    }

    .hero__content {
      padding: 0px 66px;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 34px;
      @media screen and (max-width: 940px) {
        text-align: center;
        align-items: center;
        justify-content: flex-end;
      }
      .hero__card {
        box-sizing: border-box;
        display: flex;
        padding: 26px;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        border-radius: 10px;
        width: 440px;
        background: #fff;
        @media screen and (max-width: 940px) {
          align-items: center;
          gap: 20px;
          padding: 20px;
          width: 280px;
          height: auto;
        }
      }
      .hero__members-number {
        font-size: 30px;
        h1 {
          font-family: "Averta";
          @media screen and (max-width: 940px) {
            font-size: 26px;
          }
        }
        color: white;
        width: 100%;
      }
      .hero__badges-container {
        width: 100%;
        display: flex;
        justify-content: center;
        height: fit-content;
      }
      .hero__badges {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        gap: 20px;
        row-gap: 20px;
        flex-wrap: wrap;
        width: 100%;
        @media screen and (max-width: 940px) {
          height: 234px;
          max-width: 280px;
        }
        & > a {
          &:hover {
            cursor: pointer;
          }
        }
        @media only screen and (max-width: 1534px) {
          justify-content: space-evenly;
        }
      }
    }
  }
  .help {
    display: flex;
    background: #ede8da;

    .help__content {
      display: flex;
      flex-direction: column;
      padding: 60px;
      gap: 30px;
      @media only screen and (max-width: 940px) {
        justify-content: center;
        text-align: center;
      }

      .help__content__ways {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 32px;
        flex-wrap: wrap;
        @media only screen and (max-width: 940px) {
          flex-direction: column;
        }
        .help__content__ways__card {
          width: 360px;
          height: 440px;
          display: flex;
          flex-direction: column;
          border-radius: 8px;
          border: 2px solid #d0d5dd;
          padding: 29px;
          background: #fff;
          justify-content: space-between;
          box-sizing: border-box;
          text-align: start;
          cursor: pointer;
          &:hover {
            border: 2px solid ${(props) => props.theme.colors.brand[600]};
          }
          @media only screen and (max-width: 940px) {
            height: unset;
            padding: 20px;
            width: 280px;
            text-align: center;
          }
          .help__content__ways__card__text {
            display: flex;
            flex-direction: column;
            gap: 20px;
            @media only screen and (max-width: 940px) {
              align-items: center;
            }
          }
          .help__content__ways__card__link {
            color: #3c5dff;
            font-weight: 700;
            @media only screen and (max-width: 940px) {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
  .impact-map {
    display: flex;
    .impact-map__content {
      /* TODO: text looks a little low */
      display: flex;
      gap: 20px;
      @media (max-width: 940px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .impact-map__debt-relieved {
        display: flex;
        flex-direction: column;
        justify-content: start;
        margin-top: 160px;
        @media (max-width: 940px) {
          justify-content: center;
          text-align: center;
          gap: 14px;
          margin: 60px 20px 0px 20px;
        }
        .impact-map__debt-relieved__number {
          font-family: "Averta";
          font-size: 60px;
          @media (max-width: 940px) {
            font-size: 40px;
          }
        }
      }
    }
  }
  .hear-from-members {
    display: flex;
    background: #d9d9d9;
    padding: 60px 26.667px;
    box-sizing: border-box;
    @media screen and (max-width: 940px) {
      padding: 42px 20px;
    }
    .hear-from-members__content {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 29.333px;
      flex-shrink: 0;
      @media screen and (max-width: 940px) {
        gap: 30px;
      }
      .hear-from-members__reviews {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        align-content: flex-start;
        gap: 29.333px;
        flex-wrap: wrap;
        @media screen and (max-width: 940px) {
          gap: 20px;
        }
      }
    }
  }
  .faq {
    display: flex;

    .faq__content {
      flex: 1;
      display: flex;
      padding: 60px 66.667px 60px 111.333px;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 940px) {
        padding: 42px 20px;
        gap: 20px;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
  .trust {
    display: flex;
    background: #ede8da;
    padding: 60px;

    .trust__content {
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 30px;
      display: flex;
      flex-direction: column;
    }
  }
`;
